import { css, jsx } from '@emotion/react'
import { useColorMode } from '@chakra-ui/react'
import { Heading } from '../chakra/Heading'
import Div from '../Div'
import theme from '../../constants/theme'
import useResponsive from '../../utils/hooks/useResponsive'
import { Button } from '../chakra/Button'
import VerticalSpace from '../VerticalSpace'

const styles = {
  heading: isDark => css`
    color: ${isDark ? '#fff' : theme.colors.secondary.base}
    line-height: 1.15 !important;
    text-align: center;
  `,
  subHeading: (isDark, subtitleMarginTop) => css`
    color: ${isDark ? theme.colors.text.accentedGray : theme.colors.accented.base};
    text-align: center;
    margin-top: ${subtitleMarginTop};
  `,
  mainFontSizes: ['30px', '35px', '40px', '45px'],
  mainMarginTop: ['54px', '72px'],
  secondFontSizes: ['17px', '19px', '20px', '22px'],
}

function ShareFeature() {
  const { colorMode } = useColorMode()
  const isDark = colorMode === 'dark'
  const subtitleMarginTop = useResponsive('8px', '10px', '12px')
  const buttonSize = useResponsive('sm', 'sm', 'md')

  return (
    <Div mt={styles.mainMarginTop}>
      <Heading
        block
        as="h3"
        fontWeight="extrabold"
        fontSize={styles.mainFontSizes}
        css={styles.heading(isDark)}
        className="not-selectable"
      >
        Collaborate and Share with Ease
      </Heading>

      <Heading
        block
        as="h4"
        fontWeight="medium"
        fontSize={styles.secondFontSizes}
        css={styles.subHeading(isDark, subtitleMarginTop)}
      >
        Take your bookmarking experience to the next level! Organize your favorite links into shared folders,
        collaborate with friends, and keep everyone on the same page.
      </Heading>
      <VerticalSpace space={5} />
      <Div width={styles.width} center>
        <Button
          size={buttonSize}
          css={styles.buttons}
          colorScheme="accentedButton"
          lightMode
          textTransform="uppercase"
          to="/login"
          aria-label="get started button"
        >
          Start sharing
        </Button>
      </Div>
    </Div>
  )
}

export default ShareFeature
